.admin-page-container {
  padding-block-start: 20px;
  direction: rtl;
}

@media(max-width: 980px) {
  .admin-page-container {
  margin: 8px ;

  }
}