.value-cmp-container {
  flex-direction: column;
  box-shadow: -2px -2px 20px #0000001a;
  height: 300px;
  width: 220px;
  padding: 24px 14px 18px;
  margin-block-end: 20px;
  text-align: center;
  background-color: white;
  .icon {
    margin-bottom: 30px;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .header {
    color: $logo-yellow;
    margin-bottom: 10px;
    font-size: 1.5em;
    font-weight: 700;
  }
  .content {
    font-size: 1.2rem;
  }
}

@media (max-width: 740px) {
  .value-cmp-container {
    display: grid;
    grid-template-columns: 60px 180px auto;
    gap: 0.8rem;
    align-items: center;
    height: 120px;
    width: 100%;
    
    .icon {
      margin-bottom: 0; 
      img {
        width:  45px;
        height: 45px;
      }
    }
    .header{
      font-size: 1.1em;
      margin-bottom: 0;
      margin-inline-end: 15px;
      text-align: start;
    }
    .content{
      
      font-size: 1rem;
      text-align: start;
    }
  }
}

@media(max-width: 520px) {
  .value-cmp-container { 
    display: flex;
    flex-direction: column;
    height: 240px;
    .icon {
      margin-bottom: 10px;
      margin-inline-end: 0px;
    }
    .header {
      margin-bottom: 10px;
      margin-inline-end: 0px;
    }
    .content{  
      text-align: center;
    }
  }
  
}
