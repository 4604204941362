.product-limit-container {
  select {
    height: 35px;
    margin-inline-end: 10px;
    border: 1px solid $border;
    border-radius: 5px;
    font-size: 1.2em;
  }
  justify-self: start;
}

@media (max-width:980px) {
  .product-limit-container {
    grid-row: 2/-1;
    width: 100%;
    select{
      width: 100%;
      margin-inline-end: 0;

    }
  }
}

@media (max-width:740px) {
  .product-limit-container {
    justify-self: center;
    width: 100%;
    select{
      width: 100%;
      // width: 250px
    }
  }
}