.btn {
  background-color: $logo-yellow;
  color: #000;
  font-size: 1.375em;
  font-weight: bold;
  text-align: center;
  line-height: 26px;
  height: 51px;
  width: 331px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    background-color: lighten($logo-yellow, 5%);
    cursor: pointer;
  }
  &:active {
    box-shadow: inset 0 0 8px darken($logo-yellow, 5%);
  }
}

.submit-btn {
  text-align: center;
  background-color: #fff;
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.09) 0%, rgba(17, 17, 17, 0.04) 100%);
  border: 1px solid rgba(22, 22, 22, 0.2);
  color: #444;
  max-width: 100%;
  padding: 0.5rem;
  cursor: pointer;
}
.sec-btn {
  // border: 1px solid $border;
  // width: 160px;
  // height: 35px;
  text-align: center;
  text-decoration: underline;
  color: $light-text;
  cursor: pointer;
}

.disable-btn {
  background-color: rgb(201, 201, 201);
  color: gray;
  &:hover {
    cursor: default;
  }
}
.enable-btn {
  background-color: $logo-blue;
  color: $white;

  &:hover {
    cursor: pointer;
  }
}

.simple-btn {
  cursor: pointer;
  background-color: transparent;
  color: $logo-blue;
  box-shadow: none;
  width: unset;
  height: unset;
  color: inherit;

  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  line-height: inherit;

}
