.login-page-container, .signup-page-container  {
  width: 600px;
  margin: auto;
  h2 {
    margin-block-end: 35px;
    font-weight: 700;
  }
  .btns .submit-btn{
    margin-block-end: 20px;
  }
}

@media(max-width:740px){
  .login-page-container, .signup-page-container  {
    width: 100%;
    margin: 0;
  }
}