.flex {
  display: flex;
}
.flex.row {
  flex-direction: row;
}
.flex.row-reverse {
  flex-direction: row-reverse;
}
.flex.column {
  flex-direction: column;
}
.flex.column-reverse {
  flex-direction: column-reverse;
}
.flex.column {
  flex-direction: column;
}
.flex.justify-center {
  justify-content: center;
}
.flex.justify-start {
  justify-content: flex-start;
}
.flex.justify-end {
  justify-content: flex-end;
}
.flex.align-center {
  align-items: center;
}
.flex.align-baseline {
  align-items: baseline;
}
.flex.align-start {
  align-items: flex-start;
}
.flex.align-end {
  align-items: flex-end;
}
.flex.space-between {
  justify-content: space-between;
}
.flex.space-around {
  justify-content: space-around;
}
.flex.wrap {
  flex-wrap: wrap;
}
.flex.grow-1 {
  flex-grow: 1;
}
.flex.grow-2 {
  flex-grow: 2;
}
.flex.grow-3 {
  flex-grow: 3;
}
.clean-list {
  list-style: none;
  padding: 0;
}
.text-center {
  text-align: center;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

/* Aspect ratios */

.ratio-16-9 {
  @include aspect-ratio(100, 56);
}

.ratio-square {
  @include aspect-ratio(100, 100);
}

.ratio-card {
  @include aspect-ratio(100, 80);
}

.ratio-poster {
  @include aspect-ratio(100, 150);
}

.horizontal-line {
  background-color: $light-blue;
  height: 1px;
  width: 100%;
}
