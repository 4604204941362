.main-app-container {
  min-height: 100vh;
}

.main-view-container {
  flex-grow: 1;
}

.main-layout-container {
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

@media (max-width: 1300px){
  .main-layout-container {
    max-width: 980px;

  }
}

@media (max-width: 980px) {
  
  .main-layout-container {
    max-width: 740px;
  
  }
}
