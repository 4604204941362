.no-content {
  padding-block-start: 60px;
  text-align: center;
  font-size: 1.6em;
}
.product-page-container {
  .product-top-bar {
    padding: 10px;
    box-shadow: 0 0 1px #777;
    border-radius: 2px;
    display: grid;
    grid-template-columns: repeat(3,auto) ;
    justify-items: center;
    align-items: center;
  }
}

@media (max-width:980px) {
  .product-page-container {
    .product-top-bar {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 2fr 1fr;
      grid-column-gap: 15px;
      grid-row-gap: 5px;
      justify-items: start;
    }
  }
}
@media (max-width:740px) {
  .product-page-container {
    .product-top-bar {
      grid-template-rows: 3fr 1fr;
      grid-gap: 10px;
    
    }
  }
}