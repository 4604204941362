/* Media queries Breakpoint mixins */

@mixin for-narrow-layout {
    @media (min-width: $break-narrow) {
      @content;
    }
  }
  
  @mixin for-normal-layout {
    @media (min-width: $break-normal) {
      @content;
    }
  }
  
  @mixin for-wide-layout {
    @media (min-width: $break-wide) {
      @content;
    }
  }

  /* Media  Mixins */


@mixin aspect-ratio($width, $height) {
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: ($height / $width) * 100%;
    }
    > * {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }  
    > img {
      height: 100%;
      object-fit: cover;
    }
  }
  
   /* Mixin for removing hover efects on iphone screen */
  @mixin hover-supported {    
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
  }