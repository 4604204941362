.contact-page-container {
  width: 600px;
  margin: auto;
  h2 {
    line-height: 2;
  }
  textarea {
    border: 1px solid $border;
    border-radius: 5px;
    padding-inline-start: 8px;
    min-height: 85px;
    resize: vertical;
    font-size: 1em;
    font-family: inherit;
  }

}

@media(max-width:740px) {
  .contact-page-container{

    width: 100%;
    margin: 0;
  }
  h2, h3 {
    margin-inline-start: 10px;
  }

}