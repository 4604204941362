.company-story-container {
  padding-inline-start: 40px;
  margin-top: 3.5rem;
  
  .story-header {
    z-index: 10;
    h2 {
      font-size: 2.1em;
    }
    h1 {
      font-size: 3.7em;
    }
  }
  .story-content {
    padding-inline-start: 10px;
    width: 720px;
    font-size: 1.6em;
    line-height: 1.3;
  }
  .penta {
    position: absolute;
    // top: -20%;
    top: 0;
    z-index: -1;
    overflow-y: hidden;
    img {
      max-width: 100%;
    }
    &.he-pos{
      right: 0;
      transform: scaleX(-1)
    }
    &.en-pos{
      left: 0;
      
    }
  }
  a.btn {
    padding: 10px 120px;
  }
}

@media (max-width: 980px) {
  .company-story-container{
    .story-content {
      font-size: 1.2em;
      width: fit-content;
    }
    
  }
}

@media(max-width:740px){
  .company-story-container{
    padding-block-start: 20px;
    padding-inline-start: 20px;
    .story-content{
      padding-inline-end: 10px;
    }
  }
}

@media(max-width: 540px){
  .company-story-container{
    margin-top: 2rem;
    padding-inline-start: 10px;
    .penta{
      top: -15%;
    }
  } 
}


.company-story-animated {
  z-index: -1;
}

