    
/*  COLOR PALETTE */
$border: #0000001a;
$light-text: #7a7a7a;
$row: #f5f5f5;


// ## Main colors
$logo-blue: #128C9E;
$logo-yellow: #E7C13A;
$logo-gray: #b7b6b6;
$opace-blue:rgba(18, 140, 158, 0.24);
$text: #202020;
$white: #ffffff;

$light-blue: #c6e3e8;


/* BREAKING POINTS */
$break-narrow: 460px;
$break-normal: 740px;
$break-wide: 960px;