.page-not-found-container {
    text-align: center;
    color: $text;
    max-width: 90vw;
    margin: 0 auto;
    h1 {
        margin: 0;
        font-size: 5em;
        padding-top: 25px;
    }
    h2 {
        margin: 0;
        font-size: 2.5em;
    }
    p,
    a {
        color: $light-text;
    }
    a {
        cursor: pointer;
        &:hover {
            color: $logo-blue;
        }
    }
}
