.dot-cmp-container {
  margin-inline-start: 182px;
  margin-block-end: 86px;
  .dot {
    background-color: #C4C4C4;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-inline-end: 37px;
  }
  .mark {
    background-color: $logo-yellow;
  }
}