.product-filter-container {
    input {
        border: 1px solid $border;
        border-radius: 5px;
        padding-inline-start: 8px;
        height: 35px;
        font-size: 1.1em;
        margin-inline-end: 10px;
    }
    .main-input {
        width: 250px;
    }
    .sec-input {
        width: 150px;
    }
    .filter-btn {
        margin-inline-end: 10px;
        width: 220px;
        height: 32px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }
    // get rid of arrows
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}
@media (max-width: 1300px) {
    .product-filter-container {
        .filter-btn {
            width: 180px;
        }
        .main-input {
            width: 210px;
        }
        .sec-input {
            width: 120px;
        }
        input {
            font-size: 0.95em;
        }
    }
}
@media (max-width: 980px) {
    .product-filter-container {
        grid-column: 1/-1;
        width: 100%;
        // display: grid;
        form {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 5px;
            grid-column-gap: 15px;
            .sec-input {
                grid-column: 1/2;
                grid-row: 2/3;
                width: 50%;
            }
            .term-input {
                width: 100%;
            }
            .code-input {
                justify-self: start;
            }
            .manu-input {
                justify-self: end;
                margin-inline-end: 0;
            }
            .filter-btn,
            .clear-btn {
                justify-self: center;
                grid-column: 2;
            }
        }
    }
}

@media (max-width: 740px) {
    .product-filter-container {
        width: 100%;
        form {
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            width: 100%;
            input {
                font-size: 1.1em;
                width: 90vw;
                grid-column: 1/-1;
                height: 55px;
            }
            .filter-btn {
                grid-column: 1/2;
            }
            .sec-input {
                width: 100%;
            }
            .manu-input {
                grid-column: 2;
            }
            .clear-btn {
                grid-column: 2/-1;
                font-size: 1.2em;
            }
        }
    }
}

@media (max-width: 460px) {
    .product-filter-container {
        form {
            input {
                width: 100%;
            }
            .filter-btn {
                width: 100%;
            }
        }
    }
}
