* {
    box-sizing: border-box;
}
.right{
    direction: rtl;
}
.left{
    direction: ltr;
}

html{
    color: $text;
    font-family: 'Rubik';
}

body {
    margin: 0;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
    font-size: unset;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

}

a{
    all: unset;
    color: inherit;


    &:focus{
        outline: none;
    }
}

button{
    all: unset
}


@media(max-width:740px){

    .screen{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000bc;
        z-index: 8;
        display: none;
    }
    
    .open-nav .screen{
        display: block;
        
    }
}
    
    