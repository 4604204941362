.company-value-container {
  height: 700px;
  max-width: 600px;
  padding-inline-start: 50px;
  padding-inline-end: 20px;
  padding-bottom: 20px;
  margin-top: 3.5rem;

  
  & >:nth-child(odd) {
    margin-inline-end: 20px;
  }
}

@media (max-width: 1300px){
  .company-value-container{
    max-width: 100%;
    padding-inline-start: 0;
    padding-inline-end: 0;
    height: fit-content;
    
    & >:nth-child(odd) {
      margin-inline-end: 0px;
    }
  }
  
}


@media (max-width: 980px) {
  .company-value-container {
    padding-inline-start: 80px;
    padding-inline-end: 80px;
  }
}

@media (max-width: 740px) {
  .company-value-container {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
    .animated {
      width: 100%;
    }
  }
}


