.testimonial-cmp-container {
  position: relative;
margin-block-end: 62px;
  .testimonial-header {
    margin-inline-start: 111px;
    margin-block-end: 85px;
    font-weight: normal;
    .testifiier-name {
      font-size: 40px;
      color: $logo-yellow;
    }
    .testifiier-position {
      font-size: 30px;
    }
  }
  .testimonial-content {
    font-size: 1.875em;
    width: 355px;
    margin-inline-start: 46px;
  }
  .testifier-img {
    img {
      position: absolute;
      height: 177px;
      width: 177px;
      border-radius: 50%;
      top:0;
      left: 0;
      transform: translate(-50%, -25%);
    }
  }
}