.form-box {
  user-select: none;
  font-size: 1.2em;
  width: 600px;
  margin: auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 40px;
  border-radius: 0.5rem;
  margin-block-start: 60px;
  .form-row {
    margin-block-end: 30px;
    label {
      margin-block-end: 5px;
      i {
        color: red;
        margin-inline-start: 5px;
      }
    }
    input:not([type="checkbox"]) {
      border: 1px solid $border;
      border-radius: 5px;
      padding-inline-start: 8px;
      height: 45px;
      font-size: 1em;
    }
  }

  .validation-error {
    color: red;
    font-size: 0.8em;
    margin: 4px;
  }
  .password-input {
    position: relative;
    width: 100%;
    &> input{
      width: 100%;
    }
  }
  .show-password {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }
    &.en-pos {
      left: unset;
      right: 10px;
    }
    &.he-pos {
      right: unset;
      left: 10px;
    }
  }
}

@media (max-width: 740px) {
  .form-box {
    width: 100%;
    box-shadow: none;
  }
}
@media (max-width: 460px) {
  .form-box {
    padding: 40px 15px;
  }
}
