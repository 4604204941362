.forgot-password-container {
  h3 {
    margin-block-end: 20px;
  }
  h5 {
    margin-block-end: 45px;
    width: 70%;
    line-height: 1.5;
  }
  .btns .submit-btn{
    margin-block-end: 20px;
  }
}

@media(max-width:740px){
  .forgot-password-container {
    width: 100%;
    margin: 0;
  }
}