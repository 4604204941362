.product-list-container {
  direction: ltr;
  table {
     width: 100%;
     border-collapse: collapse;
     box-shadow: 0 0 8px #ccc;
     td, th {
       text-align: start;
       padding: 8px 15px;
     }
     td:not(:last-child) {
       border-right: 1px solid #f1f1f18a;
     }
     th {
       background-color: darken($row,5%);
       border-bottom: 2px solid $logo-blue;
     }
     tr:nth-child(even){
       background-color: $row;
     }
   }
 }

 @media(max-width: 740px){
  .product-list-container{
    overflow: auto;
    table{
      td, th {
        padding: 8px 6px;
      }
      // width: 200px;
    }
  }
 }