.app-header-container {
  padding: 20px 0;
  // font-size: 1.2em;

  display: grid;
  grid-template-columns: minmax(max-content, 500px) auto minmax(
      max-content,
      500px
    );

  .logo {
    justify-self: center;
    width: 315px;
    height: 105px;
    margin-inline-end: 10px;
    background-image: url("../../assets/logos/pharma-soft-logo.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .nav-bar-container {
    margin-top: 25px;
    padding: 5px;
    a,
    a:after,
    a:before,
    .logout-btn,
    .logout-btn:after,
    .logout-btn:before {
      transition: all 0.5s;
    }
    transition: all 0.6s;
    a,
    .logout-btn {
      padding: 8px 20px;
      text-decoration: none;
      text-transform: capitalize;
      position: relative;
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: ".";
        color: transparent;
        background: $logo-blue;
        height: 1px;
      }
      &:hover {
        color: $logo-blue;
        cursor: pointer;
      }
      &:hover:after {
        width: 100%;
      }
    }
    .activeLink {
      font-weight: 700;
      color: $logo-blue;
    }

    i {
      color: $light-text;

      span {
        color: darken($light-text, 10%);
      }
    }
  }
  .lang-switch {
    margin-top: 25px;
    justify-self: end;
  }
  .hamburger {
    font-size: 1.8em;
    margin-inline-end: 10px;
    display: none;
    &:hover {
      cursor: pointer;
      color: $logo-blue;
    }
  }
}

@media (max-width: 980px) {
  .app-header-container {
    grid-template-rows: 1fr 1fr;
    padding-inline-start: 10px;
    padding-inline-end: 10px;

    .nav-bar-container {
      grid-row: 2/3;
      grid-column: 1 / -1;
    }

    .logo {
      justify-self: start;
    }
    a:first-child {
      padding-inline-start: 0;
    }
    .lang-switch {
      margin-inline-end: 0;
      grid-column: -2/ -1;
    }
  }
}

@media (max-width: 740px) {
  .he-pos {
    &.logo {
      justify-self: end;
    }
  }
  .app-header-container {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-template-rows: 1fr;
    .logo {
      background-image: url("../../assets/logos/logo512.png");
      width: 105px;
    }

    .nav-bar-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 60%;
      min-width: 280px;
      top: 100px;
      background-color: white;
      z-index: 10;
      transform: translateY(-200%);
      padding-inline-start: 30px;
      box-shadow: 0px 0px 20px black;
      border-radius: 10px;
      transition: transform 0.8s;
      &.he-pos {
        left: 15px;
      }
      &.en-pos {
        right: 15px;
      }
      a,
      .logout-btn {
        font-size: 1.4em;
        padding: 50px;
        padding-block-end: 15px;
        &:after {
          height: 3px;
        }
        &:hover:after {
          width: 80%;
        }
      }
      & > :last-child {
        padding-block-end: 50px;
      }
    }

    .lang-switch {
      grid-column: 2/3;
      justify-self: center;
      margin-top: 0;
      // align-self: center;
    }
    .hamburger {
      display: block;
      justify-self: end;
    }
  }
  .open-nav {
    .app-header-container .nav-bar-container {
      transform: translateY(0);
    }
  }
}
